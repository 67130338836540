import kaboom from "kaboom";
const ctx = kaboom.ctx;

// Start kaboom
kaboom({
  width: 1200,
  height: 800,
});

const boardWidth = width() / 2;
const boardHeight = height();
let myScore = 0;
let music;

loadFont("pacifico", "sprites/Pacifico-Regular.ttf");

loadSprite("Snowfield1", "sprites/Snowfield1.png");

loadSound("jazzychristmas", "sounds/jazzychristmas.mp3");
loadSound("snowballStrike", "sounds/snowballstrike.wav");

// Load assets
loadSprite("bean", "/sprites/bean.png")
loadSprite("enemy", "/sprites/enemy.png")

loadSprite("boy", "/sprites/winter-boy-48x48.png", {
  x: 0,
  y: 0,
  sliceX: 3,
  sliceY: 5,
  width: 48,
  height: 48,
  anims: {
    idle_right: {
      from: 0,
      to: 0,
    },
    run_right: {
      from: 1,
      to: 2,
      loop: true,
    },
    idle_left: {
      from: 3,
      to: 3,
    },
    run_left: {
      from: 4,
      to: 5,
      loop: true,
    },
    idle_down: {
      from: 6,
      to: 6,
    },
    run_down: {
      from: 7,
      to: 8,
      loop: true,
    },
    idle_up: {
      from: 9,
      to: 9,
    },
    run_up: {
      from: 10,
      to: 11,
      loop: true,
    },
    throwball: {
      from: 12,
      to: 14,
      speed: 40,
    },
  },
});

loadSprite("bad", "sprites/winter-BAD-48x48.png", {
  x: 0,
  y: 0,
  sliceX: 3,
  sliceY: 5,
  width: 48,
  height: 48,
  anims: {
    idle_right: {
      from: 0,
      to: 0,
    },
    run_right: {
      from: 1,
      to: 2,
      loop: true,
    },
    idle_left: {
      from: 3,
      to: 3,
    },
    run_left: {
      from: 4,
      to: 5,
      loop: true,
    },
    idle_down: {
      from: 6,
      to: 6,
    },
    run_down: {
      from: 7,
      to: 8,
      loop: true,
    },
    idle_up: {
      from: 9,
      to: 9,
    },
    run_up: {
      from: 10,
      to: 11,
      loop: true,
    },
    throwball: {
      from: 12,
      to: 14,
      speed: 40,
    },
  },
});


scene("game", () => {

  add([sprite("Snowfield1"), pos(0, 0), scale(1)]);
  // Define player movement speed (pixels per second)
  const SPEED = 200;

  if (music == null) {
    music = play("jazzychristmas", { loop: true });
  }

  const score = add([
    text("Score: " + myScore),
    pos(90, 24),
    { value: myScore },
  ]);

  const hits = add([
    text("Hits Taken: 0"),
    pos(width() / 2 + 35, 24),
    { value: 0 },
  ]);

  // Add player game object
  const player = add([
    sprite("boy"),
    "player",
    area(),
    // center() returns the center point vec2(width() / 2, height() / 2)
    pos(50, height() / 2),
  ]);

  let playerLife = 3;

  add([
    rect(10, height()),
    pos((width() / 2) - 5, 0),
    outline(0),
    opacity(0),
    area(),
    body({ isStatic: true }),
    "wall",
  ])


  // onKeyDown() registers an event that runs every frame as long as user is holding a certain key
  onKeyPress("a", () => {
    player.play("run_left");
  });
  onKeyDown("a", () => {
    // .move() is provided by pos() component, move by pixels per second
    player.move(-SPEED, 0);
  });
  onKeyRelease("a", () => {
    player.play("idle_left");
  });

  onKeyPress("d", () => {
    player.play("run_right");
  })
  onKeyDown("d", () => {
    player.move(SPEED, 0);
  });
  onKeyRelease("d", () => {
    player.play("idle_right");
  });


  onKeyPress("w", () => {
    player.play("run_up");
  });
  onKeyDown("w", () => {
    player.move(0, -SPEED);
  });
  onKeyRelease("w", () => {
    player.play("idle_up");
  });;

  onKeyPress("s", () => {
    player.play("run_down");
  });
  onKeyDown("s", () => {
    player.move(0, SPEED);
  })
  onKeyRelease("s", () => {
    player.play("idle_down");
  });

  let curTween = null;
  const duration = 1;
  let canThrowBall = true;

  // onClick() registers an event that runs once when left mouse is clicked
  onClick(() => {
    if (canThrowBall) {
      canThrowBall = false;
      player.play("throwball");

      const snowBall = spawnBullet(player.pos.sub(0, 50), "snowball")

      // stop previous lerp, or there will be jittering
      if (curTween) curTween.cancel()
      // start the tween
      curTween = tween(
        // start value (accepts number, Vec2 and Color)
        player.pos,
        // destination value
        mousePos(),
        // duration (in seconds)
        duration,
        // how value should be updated
        (val) => snowBall.pos = val,
        // interpolation function (defaults to easings.linear)
        easings["easeOut"],
      ).then(() => {
        snowBall.destroy();
      });
      wait(1, () => {
        canThrowBall = true;
      });
    }
  })



  function spawnBullet(bulletPos, tag) {
    const bulletNew = add([
      rect(10, 10),
      pos(bulletPos),
      color(255, 255, 255),
      area(),
      tag,
    ]);

    return bulletNew;
  }

  onUpdate(() => {
    player.pos.x = clamp(player.pos.x, 0, boardWidth - player.width);
    player.pos.y = clamp(player.pos.y, 0, boardHeight - player.height);
  });


  onCollide("enemy", "wall", (enemy, wall) => {
    console.log("Enemy collided with wall");
    enemy.hspeed = -enemy.hspeed;
    enemy.move(enemy.hspeed, 0);
  });

  onCollide("enemy", "snowball", (enemy, missile) => {
    play("snowballStrike");
    myScore += 1;
    score.text = "Score:" + myScore;
    destroy(enemy);
    destroy(missile);
  });

  onCollide("player", "missile", (player, missile) => {
    play("snowballStrike");
    playerLife -= 1;
    hits.value += 1;
    hits.text = "Hits Taken:" + hits.value;
    destroy(missile);
    if (playerLife == 0) {
      go("lose");
    }
  });

  let enemyWait = 5.0;

  function spawnEnemy() {
    if (enemyWait < 0) {
      console.log("No Longer Spawning Enemies");
    }
    console.log("Spawning Enemy");
    const enemy = add([
      sprite("bad"),
      pos(width(), rand(50, height() - 50)),
      area(),
      body(),
      "enemy",
      patrol(),
      timer(),
      {
        hspeed: 60,
      }
    ]);
    enemy.play("run_left");
    enemy.loop(5, () => {
      if (enemy.pos.x > width() - 40) { return; }
      console.log("Firing snowball: ");
      enemy.play("throwball");
      let fireTween = null;
      const bullet = spawnBullet(enemy.pos.sub(0, 50), "missile");
      enemy.play("run_left");
      if (fireTween) fireTween.cancel();
      fireTween = tween(
        enemy.pos,
        player.pos,
        duration,
        (val) => bullet.pos = val,
        easings["easeOut"],
      ).then(() => {
        bullet.destroy();
      });
    });
    wait(enemyWait, spawnEnemy); // Schedule next enemy spawn
    if (enemyWait > 1.0) {
      enemyWait = enemyWait - 0.3;
    }
  }

  function patrol(speed = 60, dir = 1) {
    return {
      id: "patrol",
      require: ["pos", "area",],
      update() {
        this.move(-speed, 0);
      },
    }
  }


  wait(1, startOutpour);

  function startOutpour() {
    spawnEnemy();
  }
});

scene("lose", () => {
  add([sprite("Snowfield1"), pos(0, 0), scale(1)]);
  add([
    text("GAME OVER", {
      size: 48, // 48 pixels tall
      width: 320, // it'll wrap to next line when width exceeds this value
      font: "sans-serif", // specify any font you loaded or browser built-in
    }),
    color(255, 0, 0),
    pos(50, height() / 2),
  ]);
  add([
    text("Your Scored: " + myScore, {
      size: 24, // 48 pixels tall
      width: 320, // it'll wrap to next line when width exceeds this value
      font: "sans-serif", // specify any font you loaded or browser built-in
    }),
    color(250, 250, 0),
    pos(50, height() / 2 + 50),
  ]);;
  add([
    text("Press Space to Restart", {
      size: 24, // 48 pixels tall
      width: 320, // it'll wrap to next line when width exceeds this value
      font: "sans-serif", // specify any font you loaded or browser built-in
    }),
    color(255, 250, 250),
    pos(50, height() / 2 + 80),
  ]);

  add([
    text("Happy Holidays!", {
      size: 60,
      width: 600,
      font: "sans-serif",
    }),
    color(255, 0, 0),
    pos(width() / 2 + 30, height() / 2),
  ]);


  // Update the final score field in the HTML
  const finalScoreField = document.getElementById('finalScoreField') as HTMLInputElement;
  if (finalScoreField) {
    finalScoreField.value = myScore.toString();
  }

  onKeyDown("space", () => {
    myScore = 0;
    finalScoreField.value = myScore.toString();
    go("game");
  });

});

scene("instructions", () => {
  add([sprite("Snowfield1"), pos(0, 0), scale(1)]);

  add([
    text("w = ↑, s = ↓ , d = →, a = ←", {
      size: 24, // 48 pixels tall
      width: 300, // it'll wrap to next line when width exceeds this value
      font: "sans-serif", // specify any font you loaded or browser built-in
    }),
    color(255, 250, 20),
    pos(50, height() / 2),
  ]);

  add([
    text("Point and Click to Throw Snowballs!", {
      size: 24, // 48 pixels tall
      width: 300, // it'll wrap to next line when width exceeds this value
      font: "sans-serif", // specify any font you loaded or browser built-in
    }),
    color(0, 0, 180),
    pos(50, height() / 2 + 40),
  ]);

  add([
    text("Press Space to Start", {
      size: 24, // 48 pixels tall
      width: 320, // it'll wrap to next line when width exceeds this value
      font: "sans-serif", // specify any font you loaded or browser built-in
    }),
    color(255, 250, 250),
    pos(50, height() / 2 + 100),
  ]);

  onKeyDown("space", () => {
    myScore = 0;
    go("game");
  });
});


go("instructions");





